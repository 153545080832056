import { useState } from 'react';
import Link from 'next/link';
import CheckIcon from '../icons/CheckIcon';
import CloseIcon from '../icons/CloseIcon';
import { cookiesData } from '@/constants/CookiesSettings';

const CookieSettingModal = ({ setShowModal, acceptCookies, locale }) => {
  const [selected, setSelected] = useState([true, false, false, false, false]);
  const [active, setActive] = useState(0);
  return (
    <div className='fixed start-0 top-0 z-[99999999999] max-h-screen min-h-screen w-screen overflow-y-auto bg-white'>
      <div className='mx-auto h-full w-3/4 max-sm:my-10 sm:w-11/12 md:p-10'>
        <h2 className='text-xl font-semibold'>
          {locale === 'en' ? 'Customize your choice' : 'قم بتخصيص اختيارك'}
        </h2>
        <h4 className='mb-6 mt-3 text-base text-black text-opacity-60'>
          {locale === 'en'
            ? 'On this page you can find more information about purposes of data processing and the vendors that we use on our websites.'
            : 'في هذه الصفحة، يمكنك العثور على مزيد من المعلومات حول أغراض معالجة البيانات والبائعين الذين نتعامل معهم على مواقعنا الإلكترونية.'}
        </h4>
        <div className='grid grid-cols-12 gap-5'>
          <ul className='col-span-full flex flex-col gap-4 sm:gap-2 sm:text-sm md:col-span-3'>
            {cookiesData.map((info, index) => (
              <li
                key={index}
                onClick={() => {
                  setActive(index);
                  if (!!index) {
                    setSelected((prev) => [
                      ...prev.slice(0, index),
                      !prev[index],
                      ...prev.slice(index + 1),
                    ]);
                  }
                }}
                className={`flex cursor-pointer items-center justify-center gap-2 rounded-md border ${active === index ? 'border-primary' : ''} p-1.5`}
              >
                <span className='flex flex-col items-center justify-center'>
                  <span
                    className={`flex ${
                      selected.at(index) ? 'flex-row' : 'flex-row-reverse'
                    } items-center`}
                  >
                    <hr
                      className={`w-10 border ${
                        selected.at(index)
                          ? 'border-green-600'
                          : 'border-red-600'
                      }`}
                    />
                    {selected.at(index) ? (
                      <span className='flex size-4 items-center justify-center rounded-full border-2 border-green-600'>
                        <CheckIcon className='size-2 text-green-600' />
                      </span>
                    ) : (
                      <span className='flex size-4 items-center justify-center rounded-full border-2 border-red-600'>
                        <CloseIcon className='size-2 text-red-600' />
                      </span>
                    )}
                  </span>
                </span>
                <span className='flex items-center justify-center gap-1'>
                  {info?.icon}
                  <span>{info[`title-${locale}`]}</span>
                </span>
              </li>
            ))}
          </ul>
          <div className='col-span-full md:col-span-9'>
            <h2 className='text-lg font-bold'>
              {cookiesData[active][`title-${locale}`]}
            </h2>
            <p className='mt-2.5 font-semibold text-black text-opacity-60'>
              {cookiesData[active][`description-${locale}`]}
            </p>
          </div>
        </div>
        <div className='xs:gap-2 mb-3 mt-5 flex items-center justify-center gap-4 max-sm:flex-col'>
          <button
            onClick={() => setShowModal(false)}
            className='xs:w-full w-40 rounded-md bg-primary p-2 text-sm font-semibold text-white'
          >
            {locale === 'en' ? 'Save + Exit' : 'حفظ + خروج'}
          </button>
          <button
            onClick={acceptCookies}
            className='xs:w-full flex w-40 items-center justify-center gap-2 rounded-md bg-primary p-2 font-semibold text-white'
          >
            <span className='text-sm font-semibold'>
              {locale === 'en' ? 'Accept All' : 'قبول الكل'}
            </span>
          </button>
          <button
            onClick={() => {
              setSelected([true, false, false, false, false]);
              setActive(0);
            }}
            className='xs:w-full w-40 rounded-md bg-primary p-2 text-sm font-semibold text-white'
          >
            {locale === 'en' ? 'Reject All' : 'قبول الضروري فقط'}
          </button>
        </div>
        <div className='flex items-center justify-center gap-4'>
          <Link
            href='/'
            className='text-sm font-semibold text-black text-opacity-60'
          >
            {locale === 'en' ? 'Privacy Policy' : 'سياسة الخصوصية'}
          </Link>
          <span className='text-black text-opacity-60'> | </span>
          <Link
            href='/static/terms'
            className='text-sm font-semibold text-black text-opacity-60'
          >
            {locale === 'en' ? 'T & C' : 'الشروط والأحكام'}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CookieSettingModal;
