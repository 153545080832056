import FunctionsIcon from '@/components/icons/FunctionsIcon';
import MarketingIcon from '@/components/icons/MarketingIcon';
import MeasurementsIcon from '@/components/icons/MeasurementsIcon';
import SlidingIcon from '@/components/icons/SlidingIcon';
import SocialIcon from '@/components/icons/SocialIcon';

export const cookiesData = [
  {
    icon: <FunctionsIcon className='size-4' />,
    'title-en': 'Functions',
    'title-ar': 'الدوال',
    'description-en':
      'These cookies and similar technologies are used for activities that are strictly necessary to operate or deliver the service you requested from us. They do not require you to consent and cannot be deactivated.',
    'description-ar':
      'تُستخدم ملفات تعريف الارتباط هذه والتقنيات المماثلة للأنشطة الضرورية تمامًا لتشغيل أو تقديم الخدمة التي طلبتها منا. ولا تتطلب موافقتك ولا يمكن تعطيلها.',
  },
  {
    icon: <MarketingIcon className='size-4' />,
    'title-en': 'Marketing',
    'title-ar': 'التسويق',
    'description-en':
      'These cookies and similar technologies help us to deliver personalized ads or marketing content to you, and to measure their performance.',
    'description-ar':
      'تساعدنا ملفات تعريف الارتباط هذه والتقنيات المماثلة في تقديم إعلانات مخصصة أو محتوى تسويقي لك، وقياس أدائها.',
  },
  {
    icon: <SlidingIcon className='size-4' />,
    'title-en': 'Pereference',
    'title-ar': 'التفضيل',
    'description-en':
      'These cookies and similar technologies help us to improve the quality of your user experience, and provide you enhanced functionality and personalisation.',
    'description-ar':
      'تساعدنا ملفات تعريف الارتباط هذه والتقنيات المماثلة على تحسين جودة تجربة المستخدم الخاصة بك، وتزويدك بوظائف وتخصيصات محسنة.',
  },
  {
    icon: <MeasurementsIcon className='size-4' />,
    'title-en': 'Measurement',
    'title-ar': 'القياسات',
    'description-en':
      "With the help of cookies and similar technologies, we're able to measure how many users visit our website and observe how you interact with our services, which aids us in making improvements.",
    'description-ar':
      'بمساعدة ملفات تعريف الارتباط والتقنيات المماثلة، نتمكن من قياس عدد المستخدمين الذين يزورون موقعنا الإلكتروني ومراقبة كيفية تفاعلك مع خدماتنا، مما يساعدنا في إجراء التحسينات.',
  },
  {
    icon: <SocialIcon className='size-4' />,
    'title-en': 'Social',
    'title-ar': 'التواصل',
    'description-en':
      'These cookies and similar technologies enable interactions with external content, networks and platforms.',
    'description-ar':
      'تتيح ملفات تعريف الارتباط هذه والتقنيات المماثلة التفاعل مع المحتوى والشبكات والمنصات الخارجية.',
  },
];
