// components/CookieConsent.js
import { getCookie, setCookie } from 'cookies-next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import CookieSettingModal from './CookieSettingModal';
import CheckIcon from '../icons/CheckIcon';

const CookieConsent = () => {
  const { locale } = useRouter();
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const consent = getCookie('cookieConsent');
    if (!consent) {
      setShowPopup(true);
    }
  }, []);

  const acceptCookies = () => {
    setCookie('cookieConsent', 'true', { maxAge: 86400 * 365 });
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <>
      <section className='fixed bottom-0 start-0 z-[999999999] w-screen bg-white pb-8 pt-12 shadow-inner sm:pb-3 sm:pt-6'>
        <div className='mx-auto grid  w-[95vw] grid-cols-4 sm:gap-5'>
          <div className='col-span-full flex flex-col gap-2 md:col-span-3'>
            <h2 className='text-lg font-semibold sm:text-sm'>
              {locale === 'ar'
                ? ' مفروشات العمر تقدر خصوصيتك !'
                : 'AlOmar values ​​your privacy!'}
            </h2>
            <p className='text-xs leading-7'>
              {locale === 'ar'
                ? `على هذا الموقع نستخدم ملفات تعريف الإرتباط ووظائف مماثله لمعالجه
            المعلومات والبيانات الشخصية ( على سبيل المثال عنوان IP ومعلومات
            المتصفح ). تستخدم المعالجه لأغراض مثل دمج وتخصيص المحتوى والتحليل
            الإحصائى / القياس والإعلانات المخصصة ودمج وسائل التواصل الإجتماعى.
            هذه الموافقه طوعيه وليست مطلوبه لاإستخدام موقعنا ويمكن إلغاؤها فى اى
            وقت من تفضيلات الإستخدام`
                : `On this site we use cookies and similar functions to process personal information and data (e.g. IP address and browser information). The processing is used for purposes such as combining and personalizing content, statistical analysis/measurement, personalized advertising and social media integration. This consent is voluntary and is not required to use our site and can be revoked at any time from the usage preferences`}
            </p>
          </div>
          <div className='col-span-full flex flex-col items-center gap-4 sm:gap-2 md:col-span-1'>
            <button
              onClick={acceptCookies}
              className='flex w-40 items-center justify-center gap-1 rounded-md bg-primary p-1.5 text-white'
            >
              <CheckIcon />
              <span className='text-sm font-semibold sm:text-[9px]'>
                {locale === 'ar' ? 'قبول الكل' : 'Accept all'}
              </span>
            </button>
            <button
              onClick={() => setShowModal(true)}
              className='w-40 rounded-md bg-primary p-1.5 text-sm font-semibold text-white sm:text-[9px]'
            >
              {locale === 'ar' ? 'الإعدادات' : 'Settings'}
            </button>
            <div className='flex items-center gap-4'>
              <Link href='/' className='text-xs text-gray-500 sm:text-[8px]'>
                {locale === 'ar' ? 'تعرف على المزيد' : 'Learn more'}
              </Link>
              <span className='text-gray-500'> | </span>
              <Link
                href='/static/terms'
                className='text-xs text-gray-500 sm:text-[8px]'
              >
                {locale === 'ar' ? 'الشروط والأحكام' : ' Terms & Conditions'}
              </Link>
              <span className='text-gray-500'> | </span>
              <Link href='/' className='text-xs text-gray-500 sm:text-[8px]'>
                {locale === 'ar' ? 'إشعار قانونى' : 'Legal notice'}
              </Link>
            </div>
          </div>
        </div>
      </section>
      {showModal ? (
        <CookieSettingModal {...{ setShowModal, acceptCookies, locale }} />
      ) : null}
    </>
  );
};

export default CookieConsent;
